<template>
  <div>
    <v-card
      style="height: 78vh;overflow-y: scroll"
    >
      <loading-provider :loading="loadingAll">
        <div
          v-dragscroll
          class="d-flex"
        >
          <div
            style="min-width: 300px;"
          >
            <loading-provider :loading="categoryLoading">
              <div
                class="sidebar pa-4 d-flex flex-column"
              >
                <v-treeview
                  :items="allCategoryList"
                  activatable
                  dense
                  open-all
                  @update:active="onNodeClick"
                >
                  <template #prepend="{ item }">
                    <v-icon
                      small
                    >
                      {{ item.icon }}
                    </v-icon>
                  </template>
                  <template #append="{item}">
                    <v-icon
                      v-if="item.id"
                      small
                      @click.stop="editCategory(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="item.id"
                      small
                      @click.stop="deleteCategory(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-treeview>
                <v-spacer />
                <v-btn
                  class="mt-4"
                  elevation="0"
                  width="100%"
                  @click="editCategory(null)"
                >
                  <v-icon>mdi-plus</v-icon>新建分类
                </v-btn>
              </div>
            </loading-provider>
          </div>
          <div
            class="d-flex flex-column pa-4"
            style="width: 70%"
          >
            <div class="d-flex">
              <div>
                <div class="text-h4 font-weight-bold">
                  原料
                </div>
                <div class="text-caption">
                  分类下的所有原料
                </div>
              </div>
              <v-spacer />
              <v-text-field
                v-model="searchResource"
                autofocus
                class="mr-2"
                dense
                hide-details
                outlined
                prepend-inner-icon="mdi-magnify"
              />
              <v-btn
                class="primary"
                elevation="0"
                @click="editResource(null)"
              >
                新增
              </v-btn>
            </div>
            <loading-provider :loading="loading">
              <v-data-table
                :headers="header"
                :items="currentTableList"
              >
                <template #item.actions="{ item }">
                  <div
                    class="pa-1 d-flex justify-end"
                  >
                    <v-icon @click="editResource(item.id)">
                      mdi-pencil
                    </v-icon>
                    <v-icon @click="deleteResource(item.id)">
                      mdi-delete
                    </v-icon>
                  </div>
                </template>
              </v-data-table>
            </loading-provider>
          </div>
        </div>
      </loading-provider>
    </v-card>
    <v-dialog
      v-model="editResourceDialog"
      max-width="500px"
      persistent
    >
      <v-card
        class="pa-4"
        elevation="0"
      >
        <base-form
          :editing-object="editResourceDetail"
          :schema="schemas"
          @close="editResourceDialog=false"
          @submit="saveResourceInfo"
        >
          <div>原料</div>
          <template #subtitle>
            在这里填写该原料所需需要的内容
          </template>
        </base-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editCategoryDialog"
      max-width="500px"
      persistent
    >
      <v-card
        class="pa-4"
        elevation="0"
      >
        <base-form
          :editing-object="editCategoryDetail"
          :schema="categorySchemas"
          @close="editCategoryDialog=false"
          @submit="saveCategoryInfo"
        >
          <div>分类</div>
          <template #subtitle>
            在这里填写该分类所需需要的内容
          </template>
        </base-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  deleteByName,
  getAllListByName,
  getCategorySchema,
  getResourceList,
  saveByName
} from '@/model/dataLayer/api/storage/Track-Beta'
import LoadingProvider from '@/views/widget/LoadingProvider'
import { dragscroll } from 'vue-dragscroll/src/main'
import UnitEditor from '@/views/AadenPages/extraModules/storage/ResourceChildrenPage/UnitEditor.vue'
import BaseForm from '@/views/widget/INK/BaseForm/BaseForm'
import EmojiPicker from '@/views/AadenPages/extraModules/storage/ResourceChildrenPage/EmojiPicker'

export default {
  name: 'ResourcePage',
  directives: {
    dragscroll
  },
  data: () => {
    return {
      searchResource: '',
      loadingAll: false,
      categoryLoading: false,
      editCategoryDetail: {},
      editCategoryDialog: false,
      categorySchemas: [],
      selectedId: null,
      editResourceDetail: {},
      schemas: [],
      editResourceDialog: false,
      resourceList: [],
      currentResourceList: [],
      loading: false,
      allCategoryList: [{ id: null, name: '全部分类', icon: '🏠️', children: [] }],
      header: [
        { text: '名字', value: 'name' },
        { text: '存量', value: 'unitDisplay', align: 'end' },
        { text: '销量', value: 'periodOutUnitDisplay', align: 'end' },
        { text: '操作', value: 'actions', align: 'end' }]
    }
  },
  components: { BaseForm, LoadingProvider },
  async mounted () {
    this.loadingAll = true
    await this.getAllCategory()
    await this.getCurrentResourceList(null)
    this.loadingAll = false
  },
  computed: {
    currentTableList () {
      if (this.searchResource) {
        return this.resourceList.filter(it => it.name.includes(this.searchResource))
      } else {
        return this.resourceList
      }
    }
  },
  methods: {
    async getAllCategory () {
      this.categoryLoading = true
      this.allCategoryList[0].children = await getAllListByName('resourceCategory')
      this.categoryLoading = false
    },
    async deleteCategory (info) {
      await deleteByName('resourceCategory', info.id)
      await this.getAllCategory()
    },
    async saveCategoryInfo (info) {
      await saveByName('resourceCategory', info)
      await this.getAllCategory()
      this.editCategoryDialog = false
    },
    async editCategory (info) {
      this.editCategoryDetail = info
      this.editCategoryDialog = true
      await this.getCategorySchemas()
    },
    async getSchemas (defaultParentId = null, selfId) {
      const resourceList = await getResourceList(null)
      const skus = resourceList.filter(it => it.id !== selfId).map(it => it.sku)
      this.schemas = [
        {
          key: 'name',
          name: 'name'
        },
        {
          key: 'primarySku',
          name: 'SKU',
          hint: 'SKUCantDuplicated',
          componentProps: {
            rules: [v => !skus.includes(v) || 'SKU ID 已被使用']
          }
        },
        {
          key: 'inventoryPeriodDays',
          name: 'SalesStatisticsTime',
          default: 7
        },
        {
          key: 'unitList',
          name: 'UnitSettings',
          hint: 'UnitSettingsHint',
          component: UnitEditor
        },
        {
          key: 'maxUnitPrice',
          name: 'MaxUnitPrice',
          componentProps: {
            type: 'number'
          }
        },
        await getCategorySchema(defaultParentId, null, false)
      ]
    },
    async getCategorySchemas () {
      this.categorySchemas = [
        {
          key: 'name',
          name: 'Name'
        },
        {
          name: 'Icon',
          key: 'icon',
          component: EmojiPicker
        }
      ]
    },
    async saveResourceInfo (info) {
      await saveByName('resource', info)
      this.editResourceDialog = false
      await this.getCurrentResourceList(this.selectedId)
    },
    async deleteResource (id) {
      await deleteByName('resource', id)
      await this.getCurrentResourceList(this.selectedId)
    },
    async editResource (id) {
      if (id) {
        const resource = this.resourceList.find(it => it.id === id)
        if (resource) {
          resource.maxUnitPrice = (resource.unitPrice * resource.maxLevelFactor).toFixed(2)
          resource.unitList = resource.units
          resource.parentId = resource.storageItemCategory?.id
        }
        this.editResourceDetail = resource
        await this.getSchemas(resource.parentId, id)
      } else {
        await this.getSchemas(null, id)
      }

      this.editResourceDialog = true
    },
    async getCurrentResourceList (id) {
      this.loading = true
      this.resourceList = await getResourceList(id)

      this.loading = false
    },
    async onNodeClick (node) {
      this.selectedId = node[0]
      await this.getCurrentResourceList(node[0])
    }
  }
}
</script>

<style scoped>
.sidebar {
  position: sticky;
  top: 0;
  max-height: 75vh;
  overflow-y: scroll
}
</style>
